import { UpperCasePipe } from "@angular/common";
import { Component } from "@angular/core";
import { Navigation, NavigationEnd, Router, RouterLink } from "@angular/router";
import { TranslateModule } from "@ngx-translate/core";

@Component({
    standalone: true,
    selector: 'app-recover-mail-send',
    templateUrl: './recover-mail-send.component.html',
    styleUrls: ['./recover-mail-send.component.scss'],
    imports: [TranslateModule, UpperCasePipe, RouterLink]
})

export class RecoverMailSendComponent {
    mail: string | undefined;

    constructor(
        private router: Router
    ) {
        this.router.events.subscribe((event: any) => {
            if (event instanceof NavigationEnd) {
                const navigation:Navigation | null = this.router.getCurrentNavigation();
                if (navigation?.extras.state) {
                    const state = navigation.extras.state;
                    this.mail = state['mail'];
                }
            }
        });
    }
}