import { Component } from "@angular/core";
import { Router, ActivatedRoute, Params } from "@angular/router";
import { SignUpResponse } from "../../models/sign-up-response.model";
import { environment } from "../../../environments/environment";

@Component({
    standalone: true,
    imports: [],
    selector: 'app-sign-up-success',
    templateUrl: './sign-up-success.component.html',
    styleUrl: './sign-up-success.component.scss'
})

export class SignUpSuccessComponent {

    client: SignUpResponse | undefined;

    constructor(private router: Router, private route: ActivatedRoute) {
        this.route.queryParams.subscribe((params: Params) => {
            const source = params['source'];
        });

        const navigationState = this.router.getCurrentNavigation()?.extras.state;


        if (navigationState) {
            const clientData: SignUpResponse = navigationState['clientData'];
            this.client = clientData
        } else {
            console.warn('No navigation state found');
        }
    }

    onContinueClick(): void {
        this.router.navigate(['onboarding'])
    }

    skipOnboarding(): void {
        const redirectUrl: string = `${environment.urls.website_url}`
        window.location.href = `${redirectUrl}`;
    }
}