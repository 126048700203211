<div id="profile">

    
    <form [formGroup]="profileForm" (ngSubmit)="onSubmit()" id="sign-up-form">
        <h2>VOTRE PROFIL</h2>

        <h3>Afin de personnaliser au mieux votre expérience, nous avons besoin d'en savoir un peu plus sur vous.</h3>

        <!--* GENDER -->
        <kin-dropdown [options]="genderOptions" id="gender"
            (change)="profileForm.controls['gender'].setValue($event)"></kin-dropdown>

        <!--* FULL NAME -->
        @let fullNameError = profileForm.controls['fullName'].invalid && profileForm.controls['fullName'].touched;
        <div tabindex="0" (focusin)="fullNameFocused = true" (focusout)="fullNameFocused = false"  class="fullName input"
            [ngClass]="{'input-error': fullNameError, touched: fullNameFocused || profileForm.controls['fullName'].touched}">
            <div class="input-header">
                <label for="fullName">{{'sign_up.input.full_name.label' | translate | titlecase}}</label>
            </div>
            <input (focus)="fullNameFocused = true" (blur)="fullNameFocused = false" type="text" id="fullName"
                formControlName="fullName" maxlength="40">
            <p class="form-error">
                @if (profileForm.controls['fullName'].errors && profileForm.controls['fullName'].errors['minlength']) {
                @let minLengthRequied = profileForm.controls['fullName'].errors['minlength'].requiredLength;
                {{('sign_up.input.name.error.minlength' | translate).replace('{minlength}', minLengthRequied)}}
                }
                @if (profileForm.controls['fullName'].errors && profileForm.controls['fullName'].errors['invalidChar'])
                {
                @let invalidChar = profileForm.controls['fullName'].errors['invalidChar'];
                {{ ('sign_up.input.name.error.invalidChar' | translate).replace('{invalidChar}',invalidChar ) }}
                }
                @if (profileForm.controls['fullName'].errors && profileForm.controls['fullName'].errors['maxlength']) {
                @let maxLengthRequied = profileForm.controls['fullName'].errors['maxlength'].requiredLength;
                {{ ('sign_up.input.name.error.maxlength' | translate).replace('{maxlength}', maxLengthRequied )}}
                }
                @if (fullNameError && profileForm.controls['fullName'].errors &&
                profileForm.controls['fullName'].errors['required']) {
                {{'sign_up.input.name.error.required' | translate}}
                }
            </p>
        </div>

        <!--* SIZE -->
        @let sizeError = profileForm.controls['size'].invalid && profileForm.controls['size'].touched;
        <div tabindex="0" (focusin)="sizeFocused = true" (focusout)="sizeFocused = false" class="size icon-input"
            [ngClass]="{'input-error': sizeError, touched: sizeFocused || profileForm.controls['size'].touched}">
            <div class="input-header">
                <label for="size">{{'sign_up.input.size.label' | translate | titlecase}}</label>
            </div>
            <kin-icon name="ruler" [color]="black500"></kin-icon>
            <input (focus)="sizeFocused = true" (blur)="sizeFocused = false" type="number" id="size"
                formControlName="size">
            <p class="form-error">
                @if (profileForm.controls['size'].errors && profileForm.controls['size'].errors['min']) {
                @let minRequied = profileForm.controls['size'].errors['min'].requiredLength;
                {{('sign_up.input.name.error.min' | translate).replace('{min}', minRequied)}}
                }
                @if (profileForm.controls['size'].errors && profileForm.controls['size'].errors['invalidChar']) {
                @let invalidChar = profileForm.controls['size'].errors['invalidChar'];
                {{ ('sign_up.input.name.error.invalidChar' | translate).replace('{invalidChar}',invalidChar ) }}
                }
                @if (profileForm.controls['size'].errors && profileForm.controls['size'].errors['max']) {
                @let maxRequied = profileForm.controls['size'].errors['max'].requiredLength;
                {{ ('sign_up.input.name.error.max' | translate).replace('{max}', maxRequied )}}
                }
                @if (sizeError && profileForm.controls['size'].errors &&
                profileForm.controls['size'].errors['required']) {
                {{'sign_up.input.name.error.required' | translate}}
                }
            </p>
        </div>

        <!--* WEIGHT NAME -->
        @let weigthError = profileForm.controls['weigth'].invalid && profileForm.controls['weigth'].touched;
        <div tabindex="0" (focusin)="weigthFocused = true" (focusout)="weigthFocused = false" class="weigth icon-input"
            [ngClass]="{'input-error': weigthError, touched: weigthFocused || profileForm.controls['weigth'].touched}">
            <div class="input-header">
                <label for="weigth">{{'sign_up.input.weigth.label' | translate | titlecase}}</label>
            </div>
            <kin-icon name="weight_scale" [color]="black500"></kin-icon>
            <input (focus)="weigthFocused = true" (blur)="weigthFocused = false" type="number" id="weigth"
                formControlName="weigth">
            <p class="form-error">
                @if (profileForm.controls['weigth'].errors && profileForm.controls['weigth'].errors['min']) {
                @let minRequied = profileForm.controls['weigth'].errors['min'].requiredLength;
                {{('sign_up.input.weight.error.min' | translate).replace('{min}', minRequied)}}
                }
                @if (profileForm.controls['weigth'].errors && profileForm.controls['weigth'].errors['invalidChar']) {
                @let invalidChar = profileForm.controls['weigth'].errors['invalidChar'];
                {{ ('sign_up.input.weight.error.invalidChar' | translate).replace('{invalidChar}',invalidChar ) }}
                }
                @if (profileForm.controls['weigth'].errors && profileForm.controls['weigth'].errors['max']) {
                @let maxRequied = profileForm.controls['weigth'].errors['max'].requiredLength;
                {{ ('sign_up.input.weight.error.max' | translate).replace('{max}', maxRequied )}}
                }
                @if (weigthError && profileForm.controls['weigth'].errors &&
                profileForm.controls['weigth'].errors['required']) {
                {{'sign_up.input.weight.error.required' | translate}}
                }
            </p>
        </div>

        <!--* BIRTHDATE -->
        @let birthdateError = profileForm.controls['birthdate'].invalid && profileForm.controls['birthdate'].touched;
        <div   class="birthdate touched icon-input"
            [ngClass]="{'input-error': birthdateError, touched: birthdateFocused || profileForm.controls['birthdate'].touched}">
            <div class="input-header">
                <label for="birthdate">{{'sign_up.input.birthdate.label' | translate | titlecase}}</label>
            </div>
            <kin-icon name="birthday" [color]="black500"></kin-icon>
            <input type="date" id="birthdate"
                formControlName="birthdate">
            <p class="form-error">
                @if(birthdateError && profileForm.controls['birthdate'].errors &&
                profileForm.controls['birthdate'].errors['minBirthdate']){
                {{ ('sign_up.input.birthdate.error.minAge' | translate).replace('{minAge}', AGE_MIN) }}
                }
                @if(birthdateError && profileForm.controls['birthdate'].errors &&
                profileForm.controls['birthdate'].errors['maxBirthdate']){
                {{ ('sign_up.input.birthdate.error.maxAge' | translate).replace('{maxAge}', MAX_AGE) }}
                }
            </p>
        </div>

        <button class="btn-primary submit-btn" type="submit" id="submit-btn">{{'oauth.sign_up' | translate | uppercase
            }}</button>
        <button (click)="location.back()" class="btn-discreet submit-btn" id="submit-btn">{{'oauth.commun.back' |
            translate |
            uppercase
            }}</button>
    </form>

</div>