<form id="reset-password-form" [formGroup]="resetPasswordForm" (ngSubmit)="onSubmit()">
    <h1>{{ 'recover.reset.title' | translate  }}</h1>
    <p>{{ 'recover.reset.security_notice' |translate }}</p>
    <!--* MAIL -->
    @let mailError = resetPasswordForm.controls['mail'].invalid && resetPasswordForm.controls['mail'].touched;
    <div tabindex="0" class="mail icon-input" (focusin)="isMailFocused = true" (focusout)="isMailFocused = false"
        [ngClass]="{'input-error': mailError, touched: isMailFocused || resetPasswordForm.controls['mail'].touched}">
        <div class="input-header">
            <label for="mail">{{ 'sign_up.input.name.mail.label' | translate }}</label>
        </div>
        <kin-icon name='mail' [color]="black100"></kin-icon>

        <input (focus)="isMailFocused = true" (blur)="isMailFocused = false" type="mail" id="mail"
            formControlName="mail">
        <p class="form-error">
            @if (mailError && resetPasswordForm.controls['mail'].errors &&
            resetPasswordForm.controls['mail'].errors['required']) {
            {{ 'sign_up.input.name.mail.error.required' | translate }}
            }
        </p>
    </div>

    <button class="btn-primary">{{ 'oauth.commun.send' | translate| uppercase }}</button>
    <button class="btn-discreet">{{ 'oauth.commun.back'| translate | uppercase }}</button>
</form>