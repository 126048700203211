import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { environment } from '../../environments/environment';
import { TokenAccess } from '../models/tokenAccess.interface';
import { ClientInformations } from '../models/clientInformation.interface';
import { errorTypes } from '../models/errorTypes.interface';

@Injectable({
  providedIn: 'root',
})
export class OauthService {
  tokenAccess: TokenAccess | null = null;
  clientInformations: ClientInformations | null = null;

  constructor(private readonly http: HttpClient) { }

  log_in(username: string, password: string): Observable<any> {
    return this.http.post(`${environment.api.login}?appToken=${environment.websiteAppToken}`, {
      grant_type: "password",
      client_id: environment.client_id,
      client_secret: environment.client_secret,
      username: username,
      password: password,
      scope: environment.scope
    })
  }

  refresh_token(tokenAccess: TokenAccess) {
    return this.http.post(environment.api.login, {
      grant_type: "refresh_token",
      client_id: environment.client_id,
      client_secret: environment.client_secret,
      refresh_token: tokenAccess.refresh_token,
      scope: environment.scope
    })
  }

  get_oauth_code(clientInformations: ClientInformations, tokenAccess: TokenAccess): Observable<any> {
    let headers = new HttpHeaders({
      "Accept": "application/json",
      "Authorization": `Bearer ${tokenAccess.token}`
    });

    return this.http.get(`${environment.api.authorize}?appToken=${environment.websiteAppToken}`, {
      headers: headers,
      params: {
        client_id: clientInformations.client_id,
        redirect_uri: clientInformations.redirect_uri,
        response_type: "code",
        scope: ""
      }
    })
  }

  redirect_error(redirect_uri: string, error: errorTypes) {
    window.location.href = `${redirect_uri}?error=${error}`;
  }

  redirect(redirect_uri: string, code: string) {
    if (this.clientInformations && this.clientInformations.state) {
      window.location.href = `${redirect_uri}?code=${code}&state=${this.clientInformations.state}`;
    }
  }

  validate_token() {
    const now = Math.round(new Date().getTime() / 1000);
    // here we check if the token is valid
    // else we check if refresh_token is valid
    // else we need a new login
  }

  decode_jwt(token: string): string {
    var base64Url = token.split('.')[1];
    var base64 = base64Url.replace(/-/g, '+').replace(/_/g, '/');
    var jsonPayload = decodeURIComponent(window.atob(base64).split('').map(function (c) {
      return '%' + ('00' + c.charCodeAt(0).toString(16)).slice(-2);
    }).join(''));

    return JSON.parse(jsonPayload).exp;
  }

  get_storage_token(): TokenAccess {
    const tokenAccess: TokenAccess = {
      token: localStorage.getItem("token") ?? '',
      refresh_token: localStorage.getItem("refresh_token") ?? ''
    }
    return tokenAccess;
  }

  set_storage_token(tokenAcess: TokenAccess): void {

    if (tokenAcess.token !== null && tokenAcess.refresh_token !== null) {
      this.tokenAccess = tokenAcess;
      localStorage.setItem("token", tokenAcess.token);
      localStorage.setItem("refresh_token", tokenAcess.refresh_token);
    }
  }

  set_client_informations(client_id: string, redirect_uri: string, state: string) {
    this.clientInformations = { client_id: client_id, redirect_uri: redirect_uri, state: state };
  }
}
