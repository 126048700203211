<div id="favorite-landscape">

    <form id="landscape-form" [formGroup]="landscapeForm" (ngSubmit)="onSubmit()">
        <h2>Vos envies</h2>

        <h3>Quel(s) type(s) de paysage(s) souhaitez-vous découvrir avec l’application Kinomap ?</h3>
        <ul>
            @for(tag of landscapes;track $index){
                @let selected = landscapeForm.controls[tag.name].value;
                <li [ngClass]="{'selected' : selected}">
                <label [for]="tag.name">
                    <kin-icon [color]="selected ? secondary : black500" [name]="getIconName(tag.name) || 'eye_off'"></kin-icon>
                    <p>{{tag.name}}</p>
                    <input [formControlName]="tag.name" type="checkbox" name="landscape" class="landscape-input"
                    [id]="tag.name" [value]="tag.id" />
                </label>
            </li>
            }

        </ul>
        <button class="btn-primary" [disabled]="!landscapeForm.valid" id="submit-btn" type="submit">SEND</button>
        <button (click)="location.back()" class="btn-discreet" >{{'oauth.commun.back' |
            translate |
            uppercase
            }}</button>
    </form>


</div>