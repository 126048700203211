import { NgClass } from "@angular/common";
import { Component } from "@angular/core";
import { ActivatedRoute, NavigationEnd, Route, Router, RouterOutlet } from "@angular/router";
import { filter, Subscription } from "rxjs";

@Component({
    selector: 'app-onboarding',
    standalone: true,
    templateUrl: './onboarding.component.html',
    styleUrl: './onboarding.component.scss',
    imports: [RouterOutlet, NgClass]
})

export class OnboardingComponent {
    childrens: string[] = [];
    currentPath: string | undefined;
    private subscription: Subscription;

    constructor(
        private router: Router,
        private route: ActivatedRoute
    ) {
        this.subscription = this.router.events
            .pipe(filter((event) => event instanceof NavigationEnd))
            .subscribe(() => this.updateCurrentPath());
    }

    ngOnInit(): void {
        const onboardingRoute = this.router.config.find((route: Route) => route.path === 'onboarding');
        if (onboardingRoute && onboardingRoute.children) {
            this.childrens = onboardingRoute.children
                .map((child: Route) => child.path)
                .filter((path: string | undefined): path is string => !!path);
        }
        this.updateCurrentPath();
    }

    private updateCurrentPath(): void {
        this.currentPath = this.route.firstChild?.snapshot.routeConfig?.path;
    }

    ngOnDestroy(): void {
        this.subscription.unsubscribe();
    }


}