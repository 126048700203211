<div id="login" [ngClass]="{ xSmallScreen: xSmallScreen }">
  <form [formGroup]="loginForm" (ngSubmit)="login()">
    <div class="input-wrapper">
      <span class="icon ic-person"></span>
      <input type="text" formControlName="username" id="username"
        [placeholder]="'oauth.login.placeholders.username_mail' | translate" autocomplete="on" required />
    </div>

    <!-- ALERTS -->
    @if(username?.errors?.['minLength']) {
    <div class="alert">
      {{ 'oauth.login.errors.mail_too_short' | translate }}
    </div>
    }

    <div class="input-wrapper">
      <span class="icon ic-lock"></span>
      <span (click)="toogleShowPassword()" [ngClass]="showPassword ? 'eye-show' : 'eye-hide'"></span>
      <input [type]="showPassword ? 'text' : 'password'" formControlName="password" id="password"
        [placeholder]="'oauth.login.placeholders.password' | translate" autocomplete="on" required />
    </div>
    <!-- ALERTS -->
    @if(password?.errors?.['required'] && (password?.touched ||
    password?.dirty)){
    <div class="alert">{{ 'oauth.login.errors.need_pw' | translate }}</div>
    } @else if(credentialError | async){
    <div class="alert">{{ 'oauth.login.errors.login_pw' | translate }}</div>
    } @else if (providerRegisterError | async) {
      <div class="alert">{{ providerRegisterError.getValue()}}</div>
    }



    <button [disabled]="!loginForm.valid" class="btn-primary" type="submit">
      {{ (loading$ | async)  ? '' :('oauth.commun.log_in' | translate | uppercase) }}
      <app-loader [loading]="(loading$ | async)"></app-loader>
    </button>

    <div class="custom-divider"></div>

    <div id="sso-container">

      @if(!xSmallScreen){
      <h3 id="sso-label">{{ 'oauth.login.connect_with' | translate }}</h3>
      }

      <div id="sso-wrapper">
        @for (ssoItem of ssoItems; track ssoItem){
        <div class="sso-item" [ngStyle]="{ background: ssoItem.color }">
          <img [src]="ssoItem.logo_url" [alt]="ssoItem.key + ' logo.'" (click)="ssoLogin(ssoItem.key)" />
        </div>
        }
      </div>
    </div>

  </form>
</div>