import { AbstractControl, ValidationErrors, ValidatorFn } from '@angular/forms';

export function domainCheckValidator(): ValidatorFn {
    return (control: AbstractControl): ValidationErrors | null => {
        const forbiddenDomains: string[] = [
            "1xp",
            "cpc",
            "0cd",
            "prc",
            "ves",
            "q0",
            "zx81",
            "wishy",
            "blip",
            "bmn",
            "iya",
            "sdj",
            "afw",
            "mynes",
            "dao",
            "nori24",
            "lerch",
            "six25",
            "ywzmb",
            "isep",
            "noreply",
            "pliz",
            "noyp",
            "zouz",
            "hunnur",
            "wxcv",
            "zorg",
            "imap",
            "redi",
            "dlvr",
            "y",
            "zinc",
            "ym",
            "yop",
            "dmts",
            "binich",
            "wzofit",
            "enpa",
            "jmail",
            "zimel",
            "yaloo",
            "jinva",
            "ealea",
            "nomes",
            "alves",
            "bibi",
            "ymail",
            "bboys",
            "ma",
            "mai",
            "autre",
            "lsyx0",
            "tweet",
            "pamil",
            "flobo",
            "toolbox",
            "bin-ich",
            "sindwir",
            "mabal",
            "degap",
            "jetable",
            "a",
            "pasdus",
            "gland",
            "nospam",
            "azeqsd",
            "le",
            "nikora",
            "sendos",
            "cubox",
            "fhpfhp",
            "c-eric",
            "bahoo",
            "upc",
            "gggggg",
            "spam",
            "alphax",
            "habenwir",
            "ist-hier",
            "sind-wir",
            "sindhier",
            "wir-sind",
            "myself",
            "vip",
            "email",
            "druzik",
            "flaimenet",
            "cloudsign",
            "yahooz",
            "tiscali",
            "altrans",
            "yoptruc",
            "kyuusei",
            "ac-cool",
            "certexx",
            "dede",
            "eureka",
            "yotmail",
            "miloras",
            "cabiste",
            "galaxim",
            "doviaso",
            "pitiful",
            "ggmail",
            "dis",
            "icidroit",
            "tmp",
            "mail",
            "y",
            "haben-wir",
            "sind-hier",
            "adresse",
            "assurmail",
            "alyxgod",
            "aze",
            "mailadresi",
            "carioca",
            "mickaben",
            "ac-malin",
            "gimuemoa",
            "woofidog",
            "rygel",
            "cheznico",
            "contact",
            "rapidefr",
            "calendro",
            "calima",
            "cobal",
            "terre",
            "imails",
            "warlus",
            "carnesa",
            "jackymel",
            "webstore",
            "freemail",
            "mr-email",
            "abo-free",
            "courrier",
            "ploki",
            "mailsafe",
            "jab",
            "sirttest",
            "machen-wir",
            "adresse",
            "poubelle",
            "lacraffe",
            "gladogmi",
            "yopmail",
            "mesemails",
            "dripzgaming",
            "mymaildo",
            "dann",
            "mymailbox",
            "dreamgreen",
            "contact",
            "mess-mails",
            "omicron",
            "torrent411",
            "ssi-bsn",
            "webclub",
            "addedbyjc",
            "vigilantkeep",
            "actarus",
            "whatagarbage",
            "test-infos",
            "mail-mario",
            "dispo",
            "ym",
            "adresse",
            "ypmail",
            "pixelgagnant",
            "m",
            "ggamess",
            "mail",
            "cool",
            "courriel",
            "jetable",
            "mega",
            "moncourrier",
            "monemail",
            "monmail",
            "nomail",
            "nospam",
            "speed",
            "yopmail"
        ];

        if (control.value) {
            const domain:string = control.value.split('@')[1]?.split('.')[0];
            if (domain && forbiddenDomains.includes(domain)) {
                return { invalidDomain: domain };
            }
        }
        return null;
    };
}
