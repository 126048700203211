import { NgClass, Location, UpperCasePipe } from "@angular/common";
import { Component } from "@angular/core";
import { FormControl, FormGroup, ReactiveFormsModule, Validators } from "@angular/forms";
import { Router } from "@angular/router";
import { BlackColorsRef, IconComponent, IconType, MainColorsRef } from "@kinomap-dev/ng-ui-lib";
import { Tag } from "@kinomap-dev/ng-ui-lib/lib/models";
import { TranslateModule } from "@ngx-translate/core";
import { OnboardingService } from "../../services/onboarding.service";

@Component({
    standalone: true,
    selector: 'app-favorite-landscape',
    imports: [ReactiveFormsModule, IconComponent, NgClass, TranslateModule, UpperCasePipe],
    templateUrl: './favorite-landscape.component.html',
    styleUrl: './favorite-landscape.component.scss'
})

export class FavoriteLandscape {

    landscapes: Tag[] = [
        {
            "id": 5,
            "name": "fields"
        },
        {
            "id": 4,
            "name": "beach"
        },
        {
            "id": 3,
            "name": "forest"
        },
        {
            "id": 2,
            "name": "city"
        },
        {
            "id": 1,
            "name": "mountain"
        }
    ]


    landscapeForm: FormGroup = new FormGroup({})


    black500: BlackColorsRef = BlackColorsRef.black_500;
    secondary: MainColorsRef = MainColorsRef.secondary;

    constructor(
        private router:Router,
        public location:Location,
        private onboardingService:OnboardingService
    ){}

    onSubmit() {
        const formControls = this.landscapeForm.controls;
        const selectedLandscapes = this.landscapes.filter((landscape:Tag) => formControls[landscape.name].value === true );
        const selectedLandscapeNames = selectedLandscapes.map((landscape:Tag)  => landscape.name);
        this.onboardingService.setLandscapes(selectedLandscapeNames)
        this.router.navigate(['onboarding','activity'], { 
            queryParams: { source: 'favorite-landscape' },
          })
      }

    getIconName(tagName: string): IconType | undefined {
        if (tagName as IconType) {
            return tagName as IconType
        }
        return undefined
    }

    // ------------

    ngOnInit(): void {
        this.landscapes.forEach((landscape: Tag) => {
            this.landscapeForm.addControl(landscape.name, new FormControl(false))
        })
    }
}