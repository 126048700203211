import {
  Component,
  ElementRef,
  OnDestroy,
  OnInit,
  ViewChild,
} from '@angular/core'
import { AsyncPipe, NgClass, NgStyle, UpperCasePipe } from '@angular/common'
import { ActivatedRoute, Router } from '@angular/router'
import { OauthService } from '../../Services/oauth.service'
import {
  FormControl,
  FormGroup,
  Validators,
  ReactiveFormsModule,
} from '@angular/forms'
import { TokenAccess } from '../../Models/tokenAccess.interface'
import { BehaviorSubject, Subscription } from 'rxjs'
import { tokenResponse } from '../../Models/tokenResponse.interface'
import { Client } from '../../Models/client.interface'
import { environment } from '../../../environments/environment'
import { SsoService } from '../../Services/sso.service'
import { HttpErrorResponse } from '@angular/common/http'
import { TranslateModule, TranslateService } from '@ngx-translate/core'
import { BreakpointObserver, Breakpoints } from '@angular/cdk/layout'
import { LoaderComponent } from '../../components/loader/loader.component'

export interface SSOitem {
  logo_url: string
  key: string
  color?: string
}

@Component({
  selector: 'app-login',
  standalone: true,
  imports: [
    NgClass,
    NgStyle,
    ReactiveFormsModule,
    TranslateModule,
    AsyncPipe,
    UpperCasePipe,
    LoaderComponent,
  ],
  templateUrl: './login.component.html',
  styleUrl: './login.component.scss',
})
export class LoginComponent implements OnInit, OnDestroy {
  showPassword: boolean = false
  tokenAccess: TokenAccess | null = null
  subscriptions: Subscription[] = []

  credentialError: BehaviorSubject<boolean> = new BehaviorSubject<boolean>(
    false,
  )
  providerRegisterError: BehaviorSubject<string | null> = new BehaviorSubject<
    string | null
  >(null)

  loading$: BehaviorSubject<boolean> = new BehaviorSubject<boolean>(false)

  xSmallScreen: boolean = false

  loginForm = new FormGroup({
    username: new FormControl('', [
      Validators.required,
      Validators.minLength(4),
    ]),
    password: new FormControl('', [
      Validators.required,
      Validators.maxLength(80),
    ]),
  })

  @ViewChild('password') passwordRef!: ElementRef
  @ViewChild('username') usernameRef!: ElementRef

  constructor(
    private observer: BreakpointObserver,
    private translate: TranslateService,
    private route: ActivatedRoute,
    private oautService: OauthService,
    private ssoService: SsoService,
    private router: Router,
  ) { }

  ssoItems: SSOitem[] = [
    {
      logo_url: '../../../assets/sso_icons/google__sso_150.png',
      key: 'google',
      color: '#ffffff',
    },
    {
      logo_url: '../../../assets/sso_icons/apple__sso_150.png',
      key: 'apple',
      color: '#000000',
    },
    {
      logo_url:
        'https://play-lh.googleusercontent.com/j-ZV144PlVuTVsLuBzIKyEw9CbFnmWw9ku2NJ1ef0gZJh-iiIN1nrNPmAtvgAteyDqU',
      key: 'starva',
      color: '#FC6B26',
    },
  ]

  get username() {
    return this.loginForm.get('username')
  }

  get password() {
    return this.loginForm.get('password')
  }

  toogleShowPassword() {
    this.showPassword = !this.showPassword
  }

  ssoLogin(ssoKey: string) {
    const params = this.route.snapshot.queryParams

    sessionStorage.setItem('provider_ss_data', JSON.stringify(params))
    this.ssoService.login(ssoKey)
  }

  login() {
    const self = this
    this.loading$.next(true)
    this.subscriptions = [
      ...this.subscriptions,
      this.oautService
        .log_in(this.loginForm.value.username!, this.loginForm.value.password!)
        .subscribe({
          next(response: tokenResponse) {
            self.tokenAccess = {
              token: response.access_token,
              refresh_token: response.refresh_token,
            }
            self.oautService.set_storage_token(self.tokenAccess)
            self.router.navigate(['/authorize'])
          },

          error(error: HttpErrorResponse) {
            self.loading$.next(false)
            if (error.status === 400) {
              console.error(error.message)
              self.credentialError.next(true)
            }
          },

          complete() {
            self.loading$.next(false)
          },
        }),
    ]
  }

  ngOnInit(): void {
    // console.log('test');

    this.subscriptions = [
      ...this.subscriptions,
      this.observer
        .observe(Breakpoints.XSmall)
        .subscribe((res) => (this.xSmallScreen = res.matches)),
    ]

    const params = this.route.snapshot.queryParams;
    const callback = this.route.snapshot.params['provider'];

    //? 1 - teste si c'est un callback ou non.
    // (la variable callback est null si ce n'est pas le cas)

    if (!callback) {
      const { client_id, redirect_uri, state } = params
      const clientsList: Client[] = environment.clientsList;
      let client: Client | undefined

      //* si les params de l'url sont correct.
      if (params && client_id && redirect_uri && state) {
        this.oautService.set_client_informations(client_id, redirect_uri, state)
        // Recupération du client
        client = clientsList.find(client => client.client_id === client_id)

        if(!client) {
          console.error(`❌ client_id : ${client_id} is not a kinomap client_id.`)
          this.router.navigate(['/not-found'])
        }
        
      } else {
        if (!params) console.error('url param errror.')
        if (!client_id) console.error('no client_id errror.')
        if (!redirect_uri) console.error('no redirect_uri errror.')
        if (!state) console.error('no state errror.')
        this.router.navigate(['/not-found'])
      }
    } else {
      /**
       *? 🟠 Here the STRAVA callback from SSO
       */
      switch (callback) {
        case 'strava':
          const data: string | null = sessionStorage.getItem('provider_ss_data')
          if (data) {
            const provider_ss_data: any = JSON.parse(data)
            const { state, code, scope } = params
            const intParamState: number = parseInt(state.replace(/^'|'$/g, ''))
            const self = this

            //? If state is correct (from SessionStorage)
            if (provider_ss_data.state == intParamState) {
              this.oautService.set_client_informations(
                provider_ss_data.client_id,
                provider_ss_data.redirect_uri,
                provider_ss_data.state,
              )

              this.subscriptions = [
                ...this.subscriptions,
                this.ssoService.loginProvider(callback, code).subscribe({
                  next(res: any) {
                    self.tokenAccess = {
                      token: res.access_token,
                      refresh_token: res.refresh_token,
                    }
                    self.oautService.set_storage_token(self.tokenAccess)
                    self.router.navigate(['/authorize'])
                  },
                  error(err: any) {
                    //? si le compte Strava n'est pas enregistré chez kinomap.
                    if (
                      err.status === 400 &&
                      err.error.error === 'user is not registered'
                    ) {
                      let errorMessage: string = self.translate.instant(
                        'oauth.login.errors.user_not_register_provider',
                      )
                      errorMessage = errorMessage.replace(
                        '${provider}',
                        callback,
                      )
                      self.providerRegisterError.next(errorMessage)
                    }
                  },
                  complete() {
                    sessionStorage.clear()
                  },
                }),
              ]
            } else {
              console.error('State is not correct !')
            }
          } else {
            console.error('No data in SS ...')
          }
          break;

        /**
         *  🟡 TECHNOGYM CALLBACK !
         *  test URL:https://login.kinomap.dev/callback-app/technogym?code=C0DD85C4E015819106ACC6F6F8EA25AC68274F398102FABA0212DDBE0D635A1FEC73FCFA1606C849DD70473378ABBEB9D5F16FFBE34ACB3AFC77AB3E7A21EB184DE410DF774823F37166EFA1B5EAA4F1&state=000       
         */
        case 'technogym':
          const { code, state } = params;

          if (code) {
            const technoGymDeepLink: string = 'com.kinomap.training://callback/technogym'

            /**
             * CALLBACK TECHNOGYM 
             */
            // this.oautService.set_client_informations(
            //   '3', // technogym => client_id
            //   `${technoGymDeepLink}?code=${code}&state=${state}`, // technogym => redirect_uri (deeplink)
            //   state, // technogym => state
            // )
            // this.router.navigate(['/authorize']);

            this.oautService.redirect(
              technoGymDeepLink,
              code,
            )
          } else {
            console.warn('It was a technogym callback, but look at the code is empty.')
          }
          break;

        default:
          console.error('no callback');
          break;
      }
    }
  }

  ngOnDestroy(): void {
    this.subscriptions.forEach((sub) => sub.unsubscribe())
  }
}
