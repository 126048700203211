import { AbstractControl, ValidationErrors, ValidatorFn } from '@angular/forms';

export function usernameValidator(): ValidatorFn {
  return (control: AbstractControl): ValidationErrors | null => {
    const forbiddenChars:RegExp = /[^a-zA-Z0-9_\-]/g;
    const invalidChar:string = control.value && control.value.match(forbiddenChars);
    if (invalidChar) {
      return { invalidChar: invalidChar[0]};
    }
    return null;
  };
}