import { UpperCasePipe } from "@angular/common";
import { Component } from "@angular/core";
import { Router } from "@angular/router";
import { TranslateModule } from "@ngx-translate/core";

@Component({
    standalone:true,
    selector:'app-redirect',
    templateUrl:'./redirect.component.html',
    styleUrl:'./redirect.component.scss',
    imports:[TranslateModule, UpperCasePipe]
})

export class RedirectComponent{


    constructor(
        private router:Router,
    ){}

    redirectClick():void{
        const redirectUrl:string = `http://www.kinomap.com`
        window.location.href = `${redirectUrl}`;
    }


}