import { Routes } from '@angular/router';
import { LoginComponent } from './Pages/login/login.component';
import { NotFoundComponent } from './Pages/not-found/not-found.component';
import { AuthorizeComponent } from './Pages/authorize/authorize.component';

export const routes: Routes = [
    { path: '', component: LoginComponent },
    { path: 'authorize', component: AuthorizeComponent },
    { path: 'callback/:provider' && 'callback-app/:provider', component: LoginComponent },
    { path: '**', component: NotFoundComponent }
];