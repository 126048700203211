<div id="sign-up">
    <h3 translate>sign_up.hook</h3>

    <form [formGroup]="form" (ngSubmit)="onSubmit()" id="sign-up-form">

        @defer(when countries.length > 0){
        <kin-dropdown id="lang" [options]="countries" (change)="selectLang($event)"
            [selected]="selectedLanguage"></kin-dropdown>
        } @placeholder {
            <kin-skeleton [height]="72" [width]="124" [borderRadius]="8" unit="px"></kin-skeleton>
        }

        <!--* NAME -->
        @let nameError = form.controls['name'].invalid && form.controls['name'].touched;
        <div tabindex="0" (focusin)="isNameFocused = true" (focusout)="isNameFocused = false" class="name input"
            [ngClass]="{'input-error': nameError, touched: isNameFocused || form.controls['name'].touched}">
            <div class="input-header">
                <label for="name">{{'sign_up.input.name.label' | translate | titlecase}}</label>
            </div>
            <input (focus)="isNameFocused = true" (blur)="isNameFocused = false" type="text" id="name"
                formControlName="name" maxlength="31">
            <p class="form-error">
                @if (form.controls['name'].errors && form.controls['name'].errors['minlength']) {
                @let minLengthRequied = form.controls['name'].errors['minlength'].requiredLength;
                {{('sign_up.input.name.error.minlength' | translate).replace('{minlength}', minLengthRequied)}}
                }
                @if (form.controls['name'].errors && form.controls['name'].errors['invalidChar']) {
                @let invalidChar = form.controls['name'].errors['invalidChar'];
                {{ ('sign_up.input.name.error.invalidChar' | translate).replace('{invalidChar}',invalidChar ) }}
                }
                @if (form.controls['name'].errors && form.controls['name'].errors['maxlength']) {
                @let maxLengthRequied = form.controls['name'].errors['maxlength'].requiredLength;
                {{ ('sign_up.input.name.error.maxlength' | translate).replace('{maxlength}', maxLengthRequied )}}
                }
                @if (form.controls['name'].errors && form.controls['name'].errors['alreadyExist']) {
                {{'sign_up.input.name.error.alreadyExist' | translate}}
                }
                @if (nameError && form.controls['name'].errors && form.controls['name'].errors['required']) {
                {{'sign_up.input.name.error.required' | translate}}
                }
            </p>
        </div>

        <!--* MAIL -->
        @let mailError = form.controls['mail'].invalid && form.controls['mail'].touched;
        <div tabindex="0" class="mail icon-input" (focusin)="isMailFocused = true" (focusout)="isMailFocused = false"
            [ngClass]="{'input-error': mailError, touched: isMailFocused || form.controls['mail'].touched}">
            <div class="input-header">
                <label for="mail">{{ 'sign_up.input.name.mail.label' | translate }}</label>
            </div>
            <kin-icon name='mail' [color]="black100"></kin-icon>

            <input (focus)="isMailFocused = true" (blur)="isMailFocused = false" type="mail" id="mail"
                formControlName="mail">
            <p class="form-error">
                @if (mailError && form.controls['mail'].errors && form.controls['mail'].errors['required']) {
                {{ 'sign_up.input.name.mail.error.required' | translate }}
                }
                @if (mailError && form.controls['mail'].errors && form.controls['mail'].errors['invalidDomain']) {
                @let invalidDomain = form.controls['mail'].errors['invalidDomain'] ;
                {{ ('sign_up.input.name.mail.error.invalidDomain' | translate).replace('{invalidDomain}',
                invalidDomain)}}
                }
                @if (mailError && form.controls['mail'].errors && form.controls['mail'].errors['apiError']) {
                {{ 'sign_up.input.name.mail.error.apiError' | translate }}
                }
            </p>
        </div>

        <!-- * PASSWORD -->
        @let passwordError = form.controls['password'].invalid && form.controls['password'].touched;
        <div tabindex="0" class="password icon-input" (focusin)="isPasswordFocused = true" (focusout)="isPasswordFocused = false"
            [ngClass]="{'input-error': passwordError, touched: isPasswordFocused || form.controls['password'].touched}">
            <div class="input-header">
                <label for="password" translate>sign_up.input.password.label</label>
            </div>
            <kin-icon [name]="form.controls['password'].valid ? 'lock_open' : 'lock'" [color]="black100"></kin-icon>
            <input #confirmPasswordInput (focus)="isPasswordFocused = true" (blur)="isPasswordFocused = false"
                [type]="passwordVisible ? 'text' : 'password'" id="password" formControlName="password">
            <kin-icon (iconClick)="passwordVisible = !passwordVisible" [name]="passwordVisible ?  'eye_on' : 'eye_off'"
                [color]="black200"></kin-icon>
            <p class="form-error">
                @if (passwordError && form.controls['password'].errors?.['required']) {
                {{ 'sign_up.input.password.error.required' | translate }}
                }
                @if (passwordError && form.controls['password'].errors?.['minlength']) {
                @let minLengthRequied = form.controls['password'].errors?.['minlength']?.requiredLength;
                {{('sign_up.input.password.error.minlength' | translate).replace('{minlength}', minLengthRequied)}}
                }
                @if (passwordError && form.controls['password'].errors?.['maxlength']) {
                @let maxLengthRequied = form.controls['password'].errors?.['maxlength']?.requiredLength;
                {{('sign_up.input.password.error.maxlength' | translate).replace('{maxlength}', maxLengthRequied)}}
                }
            </p>
        </div>

        <!-- * CONFIRM PASSWORD -->
        @let confirmPasswordError = form.controls['confirm_password'].invalid &&
        form.controls['confirm_password'].touched;
        <div tabindex="0" class="confirm-password icon-input" (focusin)="isConfirmPasswordFocused = true"
            (focusout)="isConfirmPasswordFocused = false"
            [ngClass]="{'input-error': confirmPasswordError, touched: isConfirmPasswordFocused || form.controls['confirm_password'].touched}">
            <div class="input-header">
                <label for="confirm-password" translate>sign_up.input.confirm_password.label</label>
            </div>
            <kin-icon [name]="form.controls['confirm_password'].valid ? 'lock_open' : 'lock'" [color]="black100"></kin-icon>
            <input (focus)="isConfirmPasswordFocused = true" (blur)="isConfirmPasswordFocused = false"
                [type]="confirmPasswordVisible ? 'text' : 'password'" formControlName="confirm_password"
                id="confirm-password" class="pw">
            <kin-icon (iconClick)="confirmPasswordVisible = !confirmPasswordVisible"
                [name]="confirmPasswordVisible ?  'eye_on' : 'eye_off'" [color]="black200"></kin-icon>
            <p class="form-error">
                @if (confirmPasswordError && form.controls['confirm_password'].errors?.['required']) {
                {{'sign_up.input.confirm_password.error.required' | translate}}
                }
                @if(confirmPasswordError && form.controls['confirm_password'].errors?.['matching']) {
                {{'sign_up.input.confirm_password.error.matching' | translate}}
                }
            </p>
        </div>

        <div class="cgv">
            <kin-checkbox id="cgv-confirm" (click)="onCdvClick()" [checked]="form.controls['cgv'].value"
            [inputId]="'cgv-confirm'" [name]="'cgv'">{{'sign_up.input.cgv.label' | translate}}
            <a href="#" target="_blank">{{'sign_up.commun.read_more' | translate}}</a>
        </kin-checkbox>
        </div>
        <button class="btn-primary submit-btn" [disabled]="!form.valid" type="submit" id="submit-btn">{{'oauth.sign_up' | translate | uppercase }}</button>
        <button class="btn-discreet" (click)="location.back()">{{'oauth.commun.back' | translate | titlecase}}</button>
    </form>

</div>