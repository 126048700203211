<div id="favorite-activity">
    <form [formGroup]="activityForm" (ngSubmit)="onSubmit()">
        <h2>Vos Envies</h2>
        <h3>Combien de temps avez-vous à consacrer pour votre activité physique ?</h3>
        <h4>nombre de sceance(s) par semaine</h4>
        <ul id="frequencies-list">
            @for (frequency of frequencies; track $index) {
            @let selectedFrequency = activityForm.controls['frequency'].value === frequency;
            <li [ngClass]="{'selected' : selectedFrequency}">
                <label [for]="frequency">
                    <input [ngStyle]="{'height.px':(112 / frequencies.length) * frequency}" formControlName="frequency"
                        type="radio" name="frequency" class="frequency-input" [id]="frequency" [value]="frequency" />
                    <p>{{frequency}}</p>
                </label>
            </li>
            }
        </ul>





        <h4>Durée moyenne d'une sceance</h4>
        <ul id="durations-list">
            @for (duration of activityDurations; track $index) {
            @let selectedDuration = activityForm.controls['duration'].value === duration.value;
            <li [ngClass]="{'selected' : selectedDuration}">
                <label [for]="duration.value">
                    <kin-icon [color]="selectedDuration ? secondary : black500" [name]="duration.icon"></kin-icon>
                    <p>{{duration.label}}</p>
                    <input formControlName="duration" type="radio" name="duration" class="duration-input"
                        [id]="duration.value" [value]="duration.value" />
                </label>
            </li>
            }
        </ul>

        <button class="btn-primary" type="submit">SEND</button>
        <button (click)="location.back()" class="btn-discreet submit-btn" id="submit-btn">{{'oauth.commun.back' |
            translate |
            uppercase
            }}</button>
    </form>



</div>