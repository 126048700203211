<div class="main" 
  [ngClass]="{'xSmallScreen': xSmallScreen, 'smallScreen':smallScreen, 'mediumScreen':mediumScreen,'largeScreen': largeScreen }">
  <div class="gradient-top"></div>

  <img [src]="'../assets/login-backgrounds/' + background" class="background" />

  <div class="content">
    <img class="logo" src="../assets/kinomap_primary_white.png" alt="" />
    <div class="content-router">
      <router-outlet ></router-outlet>
    </div>


  </div>
  <div class="gradient-bottom"></div>
</div>