import { Injectable } from '@angular/core';
import { ApiService } from './api.service';
import { ActivityDurationPreference } from '../pages/favorite-activity/favorite-activity.component';
import { Observable } from 'rxjs';

@Injectable({
    providedIn: 'root',
})
export class OnboardingService {

    environments: string[] = [];
    sessionPerWeek: number | null = null;
    averageSessionDuration: ActivityDurationPreference | null = null;
    gender: 'M' | 'F' | null = null;
    fullname: string | null = null;
    height: number | null = null;
    weight: number | null = null;
    birthdate: string | null = null;

    constructor(private readonly apiService: ApiService) { }

    setLandscapes(landscapes: string[]): void {
        this.environments = landscapes;
    };

    setActivities(activityFormValue: { duration: number | null, frequency: ActivityDurationPreference | null }): void {
        this.sessionPerWeek = activityFormValue.duration;
        this.averageSessionDuration = activityFormValue.frequency;
    };

    setProfile(profileFormValue: {
        birthdate:string | null,
        fullName:string | null,
        gender:'M' | 'F' | null,
        size: number | null,
        weigth:number | null,
    }): Observable<any> {
        this.birthdate = profileFormValue.birthdate;
        this.fullname = profileFormValue.fullName;
        this.gender = profileFormValue.gender;
        this.height = profileFormValue.size;
        this.weight = profileFormValue.weigth;
        return this.apiService.updateProfile(this.environments, this.sessionPerWeek, this.averageSessionDuration, this.gender, this.fullname, this.height, this.weight, this.birthdate)
    };
}
