import { NgClass, NgStyle, UpperCasePipe, Location } from "@angular/common";
import { Component } from "@angular/core";
import { FormControl, FormGroup, ReactiveFormsModule } from "@angular/forms";
import {  Router } from "@angular/router";
import { BlackColorsRef, IconComponent, IconType, MainColorsRef } from "@kinomap-dev/ng-ui-lib";
import { TranslateModule } from "@ngx-translate/core";
import { Subscription } from "rxjs";
import { OnboardingService } from "../../services/onboarding.service";

export type ActivityDurationPreference = 'less-than-20min' | 'between-20-and-30min' | 'between-30-and-45min' | 'more-than-45min' | null;

@Component({
    standalone: true,
    selector: 'app-favorite-activity',
    templateUrl: './favorite-activity.component.html',
    styleUrl: './favorite-activity.component.scss',
    imports: [ReactiveFormsModule, IconComponent, NgClass, NgStyle, TranslateModule, UpperCasePipe]
})

export class FavoriteActivity {

    favoriteLandscapes: string[] = [];
    frequencies: number[] = [1, 2, 3, 4];
    subscription:Subscription | undefined;
    activityDurations: { label: string, value: ActivityDurationPreference, icon: IconType }[] = [
        {
            label: 'Moins de 20 min',
            value: 'less-than-20min',
            icon: 'timer_less_20'
        },
        {
            label: '20 à 30 minutes',
            value: 'between-20-and-30min',
            icon: 'timer_20to30'
        },
        {
            label: '30 à 45 minutes',
            value: 'between-30-and-45min',
            icon: 'timer_30to45'
        },
        {
            label: '+ de 60 minutes',
            value: 'more-than-45min',
            icon: 'timer_more_60'
        },
    ]

    activityForm: FormGroup = new FormGroup({
        duration: new FormControl<ActivityDurationPreference>(null),
        frequency: new FormControl<number | null>(null),
    })
    black500: BlackColorsRef = BlackColorsRef.black_500;
    secondary: MainColorsRef = MainColorsRef.secondary;

    constructor(
        private router: Router,
        private onboardingService:OnboardingService,
        public location: Location,
    ) { }

    onSubmit(): void {
        this.onboardingService.setActivities(this.activityForm.value)
        this.router.navigate(['onboarding', 'profile'], {
            queryParams: { source: 'favorite-activity' },
        })
    }

}