import { Component } from "@angular/core";
import { RouterOutlet } from "@angular/router";

@Component({
    standalone:true,
    selector:'app-recover',
    template:`<router-outlet></router-outlet>`,
    styleUrls:['./recover.component.scss'],
    imports:[RouterOutlet]
})

export class RecoverComponent{

}