<div id="authorize">
  <div class="block">
    <div id="header">
      <img
        id="img-client"
        [src]="client!.logo_url"
        alt="client image"
        srcset=""
      />
      <p class="authorize-message" [innerHTML]="titleTranslation"></p>
    </div>

    @if (!xSmallScreen) {
      <div class="custom-divider"></div>
    }

    <div id="content">
      <p [innerHTML]="requestTranslation"></p>
      <div class="input-wrapper">
        <input
          type="checkbox"
          name="client-scope"
          id="client-scope-input"
          disabled
          checked
        />
        <label for="client-scope">
          {{ 'oauth.authorize.activities_scope' | translate }}
        </label>
      </div>
    </div>

    <div id="footer">
      <button
        class="btn-primary"
        [ngClass]="(loading$ | async) ? 'load' : 'wait'"
        (click)="allowAuthorize()"
      >
        {{ (loading$ | async)  ? '' : ('oauth.commun.allow' | translate | uppercase) }}
        <app-loader [loading]="(loading$ | async)"></app-loader>
      </button>
      <button class="btn-secondary" (click)="navtoLogin()" translate>
        {{ 'oauth.commun.back' | translate }}
      </button>
      <p [innerHTML]="termsTranslation"></p>
    </div>
  </div>
</div>
