export const backgrounds:string[] = ['img_background_cycling.jpg',
'img_background_rowing.jpg',
'img_background_running.jpg',
'img_cycling_3.jpg',
'img_filter_running.jpg',
'img_onboarding_connexion_step_bitgym_bike.png',
'img_onboarding_your_desire_step.png',
'img_rowing_3.jpg',
'img_running_2.jpg',
]
