import { NgClass, UpperCasePipe } from "@angular/common";
import { Component } from "@angular/core";
import { FormControl, FormGroup, ReactiveFormsModule, Validators } from "@angular/forms";
import { BlackColorsRef, IconComponent } from "@kinomap-dev/ng-ui-lib";
import { TranslateModule } from "@ngx-translate/core";
import { Router } from "@angular/router";
import { ApiService } from "../../services/api.service";

@Component({
    standalone: true,
    selector: 'app-reset-password',
    templateUrl: './reset-password.component.html',
    styleUrl: './reset-password.component.scss',
    imports: [IconComponent, UpperCasePipe, ReactiveFormsModule, TranslateModule, NgClass]
})

export class ResetPasswordComponent {
    passwordFocused: boolean = false;
    black100: BlackColorsRef = BlackColorsRef.black_100
    isMailFocused: boolean = false;
    resetPasswordForm: FormGroup = new FormGroup({
        mail: new FormControl<string | null>(null, Validators.required)
    })
    mail: string | undefined;

    constructor(
        private apiService: ApiService,
        private router: Router
    ) { }


    onSubmit() {
        this.apiService.recover(this.resetPasswordForm.value.mail).subscribe({
            complete: () => {
                this.router.navigate(['recover', 'mail-send'], {
                    state: {
                        mail: this.resetPasswordForm.value.mail
                    }
                })
            }
        })
    }
}