<div id="sign-up-success">

    <div id="client">
        <img [src]="'https://ui-avatars.com/api/?name=' + client?.userName " alt="" srcset="">
        <div id="client-flag"></div>
        <p id="welcome">Bienvenue</p>
        <p id="client-username">{{ client?.userName }}</p>
        <p  id="msg">Prenons quelques instants pour configurer votre profile.</p>
    </div>

    <footer>
        <button class="btn-primary" (click)="onContinueClick()">configurer votre profile</button>
        <button (click)="skipOnboarding()" class="btn-discreet">configurer plus tard</button>
    </footer>
</div>