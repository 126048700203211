<div id="recover-mail-send">
    <h1>{{ 'recover.title.mail_send' | translate }}</h1>
    <p>
        @defer (when mail) {
        <ng-container i18n="@@recover.mail_send">
            Un e-mail a été envoyé à <span class="email-highlight">{{ mail }}</span>.
        </ng-container>
        }
    </p>
    <button class="btn-primary" routerLink="/">{{ 'oauth.commun.back' | translate | uppercase }}</button>
</div>