import { Component, OnInit } from '@angular/core'
import { NgClass } from '@angular/common'
import { ActivatedRoute, NavigationEnd, Route, Router, RouterOutlet } from '@angular/router'
import { OauthService } from './services/oauth.service'
import { backgrounds } from '../assets/login-backgrounds/login-backgrounds.list'
import { filter, Subscription } from 'rxjs'
import {
  BreakpointObserver,
  Breakpoints,
  LayoutModule,
} from '@angular/cdk/layout'
import { TranslateModule, TranslateService } from '@ngx-translate/core'

@Component({
  selector: 'app-root',
  standalone: true,
  imports: [
    NgClass,
    RouterOutlet,
    LayoutModule,
    TranslateModule,
  ],
  providers: [OauthService],
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss'],
})
export class AppComponent implements OnInit {
  subscriptions: Subscription[] = []

  xSmallScreen: boolean = false
  smallScreen: boolean = false
  mediumScreen: boolean = false
  largeScreen: boolean = false
  background: string = ''

  showLogo: boolean = true;

  constructor(
    private observer: BreakpointObserver,
    private translateService: TranslateService,
    private router: Router,
    private route: ActivatedRoute,
  ) { }


  getbackground(): string {
    const index: number = Math.floor(Math.random() * backgrounds.length)
    return backgrounds[index]
  }

  ngOnInit(): void {
    this.background = this.getbackground()
    this.translateService.addLangs([
      'en-EN',
      'fr-FR',
      'de-DE',
      'en-US',
      'es-ES',
      'it-IT',
      'nl-NL',
      'jp-JP',
    ])
    let browserLang = this.translateService.getBrowserLang()
    browserLang = browserLang + '-' + browserLang?.toString().toUpperCase()
    const availableLangs = this.translateService.getLangs()
    if (browserLang) {
      const selectedLang = availableLangs.includes(browserLang)
        ? browserLang
        : 'en-EN'
      this.translateService.use(selectedLang)
    } else {
      console.error('Language not found ... ')
      this.translateService.use('en-EN')
    }

    this.subscriptions = [

      this.router.events
        .pipe(filter((event) => event instanceof NavigationEnd))
        .subscribe((path:NavigationEnd) => {
          this.showLogo = !path.toString().includes('onboarding')
        }),

      this.observer
        .observe(Breakpoints.XSmall)
        .subscribe((res) => (this.xSmallScreen = res.matches)),
      this.observer
        .observe(Breakpoints.Small)
        .subscribe((res) => (this.smallScreen = res.matches)),
      this.observer
        .observe(Breakpoints.Medium)
        .subscribe((res) => (this.mediumScreen = res.matches)),
      this.observer
        .observe(Breakpoints.Large)
        .subscribe((res) => (this.largeScreen = res.matches)),
    ];
  }
}
