import { Component, ElementRef, ViewChild } from "@angular/core";
import { TranslateModule } from "@ngx-translate/core";
import { BlackColorsRef, CheckboxComponent, Country, DropdownComponent, DropdownOption, IconComponent, SkeletonComponent } from "@kinomap-dev/ng-ui-lib";
import { FormGroup, FormControl, ReactiveFormsModule, Validators } from "@angular/forms";
import { NgClass, TitleCasePipe, UpperCasePipe, Location } from "@angular/common";
import { Md5 } from 'ts-md5';
import { usernameValidator } from "../../validators/username.validator";
import { HttpErrorResponse } from "@angular/common/http";
import { domainCheckValidator} from "../../validators/mail-check.validator";
import { matchValidator } from "../../validators/confirm-password.validator";
import { Router } from "@angular/router";
import { ApiService } from "../../services/api.service";

@Component({
    selector: 'app-sign-up',
    standalone: true,
    imports: [
        TranslateModule,
        DropdownComponent,
        ReactiveFormsModule,
        NgClass,
        UpperCasePipe,
        CheckboxComponent,
        TitleCasePipe,
        SkeletonComponent,
        IconComponent,
    ],
    templateUrl: './sign-up.component.html',
    styleUrl: './sign-up.component.scss',
})

export class SignUpComponent {

    countries: DropdownOption<number>[] = [];
    isNameFocused: boolean = false;
    isMailFocused: boolean = false;
    isPasswordFocused: boolean = false;
    isConfirmPasswordFocused: boolean = false;
    selectedLanguage: DropdownOption<number> | undefined = undefined;

    form: FormGroup = new FormGroup({
        name: new FormControl<string | null>(null, [Validators.required, Validators.minLength(4), usernameValidator(), Validators.maxLength(30)]),
        mail: new FormControl<string | null>(null, [Validators.email, Validators.required, domainCheckValidator()]),
        password: new FormControl<string | null>(null, [Validators.required, Validators.minLength(6), Validators.maxLength(30),matchValidator('confirm_password', true)]),
        confirm_password: new FormControl<string | null>(null, [Validators.required, matchValidator('password')]),
        lang: new FormControl<number | null>(null, Validators.required),
        cgv: new FormControl<boolean>(false, Validators.requiredTrue),
    });

    passwordVisible: boolean = false;
    confirmPasswordVisible: boolean = false;

    black100:BlackColorsRef = BlackColorsRef.black_100;
    black200:BlackColorsRef = BlackColorsRef.black_200;

    @ViewChild('passwordInput', {static:true}) passwordInputRef!:ElementRef<HTMLInputElement>;
    @ViewChild('confirmPasswordInput', {static:true}) confirmPasswordInputRef!:ElementRef<HTMLInputElement>;

    constructor(
        private api: ApiService,
        private router:Router,
        public location:Location,
    ) { }

    onSubmit(): void {
        const passMD5: string = Md5.hashStr(this.form.value['password']);
        this.api.signUp(
            this.form.value['name'],
            this.form.value['mail'],
            this.form.value['lang'],
            passMD5,
            navigator.language.slice(0, 2) || 'en-EN',
        ).subscribe({
            next: (value) => {
                this.router.navigate(['sign-up-success'], { 
                    queryParams: { source: 'sign-up' },
                    state: { clientData: value }
                  });
            },
            error: (error: HttpErrorResponse) => {
                switch (error.status) {
                    case 400:
                    case 409:
                        this.setResponseError(error);
                        break;
                    default:
                        console.error("undefined api erreur.")
                        break
                }
            },
        })
    }

    onCdvClick():void{
        const cgvValue:boolean = this.form.controls['cgv'].value;
        this.form.controls['cgv'].setValue(!cgvValue)
    }

    private setResponseError(e: HttpErrorResponse): void {
        const msg:string = e.error.data.message;
        switch (msg) {
            case 'invalid email':
                this.form.controls['mail'].setErrors({ apiError: true })
                break;

            case 'Username already exists':
                this.form.controls['name'].setErrors({ alreadyExist: true })
                break;

            default:
                console.error(msg)
                break
        }
    }

    selectLang(lanId: number) {
        this.form.patchValue({ lang: lanId })
    }

    ngOnInit(): void {
        const navLang: string = navigator.language.slice(0, 2);
        this.api.getCountries().subscribe((countries: Country[]) => {
            countries.forEach((country: Country) => {
                const countryOption: DropdownOption<number> = {
                    value: country.id,
                    img: 'https://storybook.kinomap.dev/assets/storybook-assets/flags/' + country.code.slice(0, 2) + '.svg'
                }
                this.countries.push(countryOption)
                if (navLang === country.code) {
                    this.selectedLanguage = countryOption;
                    this.selectLang(country.id)
                }
            });
        });
    }
}