import { Injectable } from '@angular/core';
import { ApiHttpResponse, User } from '@kinomap-dev/ng-ui-lib';
import { map, Observable } from 'rxjs';
import { environment } from '../../environments/environment';
import { HttpClient } from '@angular/common/http';

@Injectable({
  providedIn: 'root'
})
export class UserService {

  constructor(
    private http: HttpClient
  ) { }


  /**
   * Permet de recupere les infos d'un client / user.
   * @param userId 
   * @returns 
   */
  getUser(userId: string): Observable<User> {
    const url: string = `${environment.urls.api_url}users/${userId}?appToken=${environment.websiteAppToken}`;
    return this.http.get<ApiHttpResponse<User>>(url).pipe(
      map((userResponse:ApiHttpResponse<User>) => userResponse.data) 
    )
  }
}
