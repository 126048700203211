const API_URL = "https://api.kinomap.dev/v4/";
const OAUTH_URL = "https://login.kinomap.dev/"

export const environment = {
    production: false,
    client_id: 1,
    client_secret: "pRFFDCLeFKlFlQyNs3jWErL4bu3APNqi0XKn7hQj",
    websiteAppToken:'1cey0vihf7f5359ddfaapxxiptpmvqzq9ce12442rzvk1dolflmd7d3bn6egzeofksgh4jwd4wmmmomxcotfqovcc3qkvr342bwk4ga1v5b3ngsshrnfukyj',
    scope: "*",
    api: {
        login: `${API_URL}oauth/token`,
        authorize: `${API_URL}oauth/authorize`,
        loginProvider:`${API_URL}oauth/sso/login/`
    },
    clientsList: [
        {
            client_id: '2',
            client_name: 'sportheroes',
            logo_url: 'assets/clients_icons/sport-heroes-logo.svg',
            scopes: [
                'activities'
            ],
        },
        {
            client_id: '3',
            client_name: 'technogym',
            logo_url: 'assets/clients_icons/technogym-logo.svg',
            scopes: [
                'activities'
            ],
        },
        {
            client_id: '6',
            client_name: 'defit',
            logo_url: 'assets/clients_icons/defit-logo.svg',
            scopes: [
                'activities'
            ],
        }
    ],

    sso: {
        //✅ Google 
        google: {
            client_id: '466170937286-7ko89334cm4cvrt4pa1728cdmp5he3qi.apps.googleusercontent.com',
            loginUrl: API_URL + 'oauth/sso/loginGoogle'
        },
        //✅ Apple 
        apple: {
            loginUrl: API_URL + 'oauth/sso/loginApple',
            redirect_url: OAUTH_URL + 'callback/apple',
        },

        //❓ Strava 
        strava: {
            kinTokenUrl: API_URL  + 'oauth/sso/loginStrava',
            loginUrl: 'https://www.strava.com/oauth/authorize',
            redirectUrl: OAUTH_URL+ 'callback/strava',
            tokenUrl: 'https://www.strava.com/oauth/token',
            config: {
                client_secret: '00571e843cd0805dc16ca7aa360f7d866cd9e5d8',
                client_id: 8927,
            }
        },

        //❌ Decathlon 
        decathlon: {
            loginUrl: 'https://api-global.decathlon.net/connect/oauth/authorize',
            dataUrl: 'https://api-global.decathlon.net/connect/oauth/token',
            redirectUrl: OAUTH_URL + 'callback/decathlon',
            config: {
                client_id: '0cd4eea3-363d-4606-9faa-a93fcac6d946',
                client_secret:
                    '0cTZNom4AZ1dHaIFHA3gksJsIvkegI8vQ4wNEtKHY0MqgLtEzanNCTy42Im1HcGJfvgIMNZ8BCFTUTxtyC0R7pcfv1Upw6yAUpLQs05uHRAMnrBHqRZtogncdlrhedQC',
            },
        },
    }
};
