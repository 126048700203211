import {
    DatePipe, NgClass, TitleCasePipe, UpperCasePipe, Location
} from "@angular/common";
import { Component } from "@angular/core";
import { FormControl, FormGroup, ReactiveFormsModule, Validators } from "@angular/forms";
import { BlackColorsRef, Country, DropdownComponent, DropdownOption, IconComponent } from "@kinomap-dev/ng-ui-lib";
import { TranslateModule, TranslateService } from "@ngx-translate/core";
import { minAgeValidator } from "../../validators/min-age.validator";
import { maxAgeValidator } from "../../validators/max-age.validator";
import { Router } from "@angular/router";
import { Subscription } from "rxjs";
import { OnboardingService } from "../../services/onboarding.service";

@Component({
    standalone: true,
    selector: 'app-profile',
    templateUrl: './profile.component.html',
    styleUrl: './profile.component.scss',
    imports: [
        ReactiveFormsModule,
        TranslateModule,
        TitleCasePipe,
        NgClass,
        UpperCasePipe,
        DropdownComponent,
        IconComponent,
    ]
})

export class ProfileComponent {
    // CONFIG
    AGE_MIN: number = 15;
    MAX_AGE: number = 110;

    genderFocused: boolean = false;
    fullNameFocused: boolean = false;
    countryFocused: boolean = false;
    sizeFocused: boolean = false;
    weigthFocused: boolean = false;
    birthdateFocused: boolean = true;

    countries: DropdownOption<number>[] = [];
    selectedLanguage: DropdownOption<number> | undefined = undefined;
    genderOptions: DropdownOption<string | null>[] = []

    datePipe: DatePipe = new DatePipe(navigator.language || 'en-EN');
    now: string = this.datePipe.transform(new Date(), 'yyyy-MM-dd') || '';
    minBirthdate: string = this.datePipe.transform(new Date(new Date().setFullYear(new Date().getFullYear() - this.AGE_MIN)), 'yyyy-MM-dd') || '';
    maxBirthdate: string = this.datePipe.transform(new Date(new Date().setFullYear(new Date().getFullYear() - this.MAX_AGE)), 'yyyy-MM-dd') || '';
    profileForm: FormGroup = new FormGroup({
        gender: new FormControl<'M' | 'F' | null>('F', Validators.required),
        fullName: new FormControl<string>('', [Validators.required, Validators.maxLength(40), Validators.minLength(4)]),
        size: new FormControl<number | null>(null, [Validators.required, Validators.max(250), Validators.min(80)]),
        weigth: new FormControl<number | null>(null, [Validators.required, Validators.max(250), Validators.min(40)]),
        birthdate: new FormControl<string>(this.minBirthdate, [minAgeValidator(this.minBirthdate), maxAgeValidator(this.maxBirthdate), Validators.required]),
    })

    black500: BlackColorsRef = BlackColorsRef.black_500;
    subscription: Subscription | undefined;

    constructor(
        private router: Router,
        public location: Location,
        private translate: TranslateService,
        private onboardingService: OnboardingService,
    ) { }


    onSubmit(): void {
        this.onboardingService.setProfile(this.profileForm.value).subscribe({
            next: () => {
                this.router.navigate(['redirect']);
                // TODO => verifier la 401 => KIN-10146 (https://kinomap.atlassian.net/browse/KIN-10146)
            },
            error: (error:Error) => {
                console.error(error)
            }
        })
    }

    ngOnInit(): void {
        this.genderOptions = [
            {
                label: this.translate.instant('oauth.commun.man'),
                value: 'M',
            },
            {
                label: this.translate.instant('oauth.commun.woman'),
                value: 'F',
            },
            {
                label: this.translate.instant('oauth.commun.other'),
                value: null,
            },
        ]
    }
}