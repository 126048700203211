import { Inject, Injectable, NgZone } from '@angular/core'
import { Router } from '@angular/router'
import { DOCUMENT } from '@angular/common'
import { HttpClient, HttpErrorResponse, HttpResponse } from '@angular/common/http'
import { Buffer } from 'buffer'
import { TokenAccess } from '../models/tokenAccess.interface'
import { OauthService } from './oauth.service'
import { environment } from '../../environments/environment'
import { Observable } from 'rxjs'

declare const google: any
declare const AppleID: any
declare const gapi: any

@Injectable({
  providedIn: 'root',
})
export class SsoService {
  tokenAccess: TokenAccess | null = null

  constructor(
    @Inject(DOCUMENT) private document: Document,
    private router: Router,
    private http: HttpClient,
    private oautService: OauthService,
    private ngZone: NgZone
  ) { }

  login(ssoKey: string) {
    switch (ssoKey) {
      /**
       ** Apple sso login.
       */
      case 'apple':
        AppleID.auth.init({
          clientId: 'com.kinomap',
          scope: 'name email',
          redirectURI: environment.sso.apple.redirect_url,
          usePopup: true,
        })
        this.signInAppleReturn();
        break

      /**
       ** Google sso login.
       */
      case 'google':
        this.signInGoogle()
        break;




      /**
       ** Stava sso login.
       */
      case 'starva':
      const sessionData = sessionStorage.getItem('provider_ss_data')
      let state:number = 0;
      if(sessionData){
         state =  JSON.parse(sessionData).state;
      }
        this.document.location.href = `${environment.sso.strava.loginUrl}?client_id=${environment.sso.strava.config.client_id}&response_type=code&scope=read_all,profile:read_all,activity:read_all,activity:write&redirect_uri=${environment.sso.strava.redirectUrl}&state='${state}'`
        break;


      /**
       ** Decathlon sso login.
       */
      case 'decathlon':
        this.document.location.href = `${environment.sso.decathlon.loginUrl
          }?client_id=${environment.sso.decathlon.config.client_id
          }&response_type=code&redirect_uri=${environment.sso.decathlon.redirectUrl
          }&state=${this.shuffle(
            15,
          )}&scope=profile openid email sports_tracking_data`
        break

      default:
        this.router.navigate(['/login'])
        break
    }
  }

  /**
   * ? 🎨 GOOGLE LOGIN
   */
  signInGoogle() {
    google.accounts.id.initialize({
      client_id: environment.sso.google.client_id,
      mode_ux: 'popup',
      cancel_on_tap_outside: false,
      callback: (response: any) => {
        const responsePayload = this.decodeJwtResponse(response.credential)
        const params = {
          id: responsePayload.sub,
          email: responsePayload.email,
          name: responsePayload.name,
          firstname: responsePayload.given_name,
          lastname: responsePayload.family_name,
          picture: responsePayload.picture,
        }

        this.http
          .post(
            `${environment.sso.google.loginUrl}?appToken=${environment.websiteAppToken}`,
            {
              google_data: JSON.stringify(params),
            },
          )
          .subscribe({
            next: (response: any) => {
              this.tokenAccess = {
                token: response.access_token,
                refresh_token: response.refresh_token,
              }
              this.oautService.set_storage_token(this.tokenAccess);
              this.navToAuthorize();
            },
            error: (e: HttpErrorResponse) => {
              this.catchHttpResponseError(e)
            },
          })
      },
    })
    google.accounts.id.prompt((notification: any) => {
      if (notification.isNotDisplayed() || notification.isSkippedMoment()) {
        document.cookie = `g_state=;path=/;expires=Thu, 01 Jan 1970 00:00:01 GMT`
        google.accounts.id.prompt()
      }
    })
  }

  /**
   * ? 🍎 APPLE LOGIN
  */
  signInAppleReturn() {
    try {
      const resp: Promise<any> = AppleID.auth.signIn();
      resp.then((data) => {

        console.log(data);

        const authorization = data.authorization;
        const token = authorization.id_token.split('.');
        const decoded = Buffer.from(token[1], 'base64').toString('binary');

        const userData = JSON.parse(decoded);

        const params = {
          id: userData.sub,
          email: userData.email,
          firstname: null,
          lastname: null,
        }

        // console.log(userData);


        if (data.user) {
          params.firstname = data.user.name.firstName
          params.lastname = data.user.name.lastName
        }

        this.http.post(
          `${environment.sso.apple.loginUrl}?appToken=${environment.websiteAppToken}`,
          { apple_data: JSON.stringify(params) },
        )
          .subscribe({
            next: (response: any) => {

              console.log(response);

              this.tokenAccess = {
                token: response.access_token,
                refresh_token: response.refresh_token,
              }

              this.oautService.set_storage_token(this.tokenAccess);
              this.navToAuthorize();
            },
            error: (e) => this.catchHttpResponseError(e),
          })
      })
    } catch (error) {
      console.error('Api is not accessible', error)
    }
  }


  navToAuthorize() {
    this.ngZone.run(() => {
      this.router.navigate(['/authorize']);
    });
  }

  async signInAppleReturn2() {
    try {
      const data = await AppleID.auth.signIn();
    } catch (error) {

    }
  }


  decodeJwtResponse(token: string) {
    const _decodeToken = (token: string) => {
      try {
        return JSON.parse(atob(token))
      } catch {
        return
      }
    }
    return token
      .split('.')
      .map((token) => _decodeToken(token))
      .reduce((acc, curr) => {
        if (!!curr) acc = { ...acc, ...curr }
        return acc
      }, Object.create(null))
  }

  shuffle(len: any) {
    const str = '0123456789abcdefghjkmnpqrstuvwxyzABCDEFGHIJKLMNOPQRSTUVWXYZ'
    const arr = str.split('')
    const n = arr.length

    for (var i = 0; i < n - 1; ++i) {
      var j = Math.floor(Math.random() * n)
      var temp = arr[i]
      arr[i] = arr[j]
      arr[j] = temp
    }

    return arr.slice(0, len).join('')
  }

  validateLogin(response: any) {
    const { data } = response

    if (response && data && data.userAccessToken) {
      localStorage.setItem('user_access_token', data.userAccessToken)
      const currentUser = {
        username: data.userName,
        avatar: data.userAvatars,
        email: data.userEmail,
        roles: data.roles,
      }

      setTimeout(() => {
        const token = data.userAccessToken
      }, 1000)
    }
  }


  loginStrava(strava_data:any):Observable<any>{
    //{{api_url}}/oauth/sso/loginStrava?appToken={{app_token}}
     return this.http.post(environment.sso.strava.kinTokenUrl + `?appToken=${environment.websiteAppToken}`, {
      strava_data:strava_data
    })
  }

  loginProvider(provider:string, code:string):Observable<any>{
    const url:string = `${environment.api.loginProvider}${provider}?appToken=${environment.websiteAppToken}`;
    return this.http.post(url, {code: code});
  }


  catchHttpResponseError(e: HttpErrorResponse) {
    switch (e.status) {
      case 401:
        console.error('401 error !')
        console.error(e)
        break
      case 409:
        console.error(e.statusText, 'ERROR : email already exists error !')
        break
      default:
        console.error(e)
        break
    }
  }
}
