import { Routes } from '@angular/router';
import { LoginComponent } from './pages/login/login.component';
import { NotFoundComponent } from './pages/not-found/not-found.component';
import { AuthorizeComponent } from './pages/authorize/authorize.component';
import { OnboardingComponent } from './pages/onboarding/onboarding.component';
import { FavoriteLandscape } from './pages/favorite-landscape/favorite-landscape.component';
import { SignUpComponent } from './pages/sign-up/sign-up.component';
import { SignUpSuccessComponent } from './pages/sign-up-success/sign-up-success.component';
import { FavoriteActivity } from './pages/favorite-activity/favorite-activity.component';
import { ProfileComponent } from './pages/profile/profile.component';
import { RedirectComponent } from './pages/redirect/redirect.component';
import { ResetPasswordComponent } from './pages/reset-password/reset-password.component';
import { RecoverComponent } from './pages/recover/recover.component';
import { RecoverMailSendComponent } from './pages/recover-mail-send/recover-mail-send.component';
import { ChangeAccountComponent } from './pages/change-account/change-account.component';

export const routes: Routes = [
    { path: '', component: LoginComponent },
    { path: 'authorize', component: AuthorizeComponent },
    { path: 'callback/:provider', component: LoginComponent },
    { path: 'callback-app/:provider', component: LoginComponent },
    { path: 'sign-up', component: SignUpComponent },
    { path: 'sign-up-success', component: SignUpSuccessComponent },
    { path: 'change-account', component: ChangeAccountComponent },
    {
        path: 'onboarding', component: OnboardingComponent, 
        children: [
            { path: '', redirectTo: 'landscape', pathMatch: 'full' },
            { path:'landscape', component:FavoriteLandscape },
            { path:'activity', component:FavoriteActivity },
            { path:'profile', component:ProfileComponent },
            { path:'redirect', component:RedirectComponent },
        ]
    },
    {path:'recover', component:RecoverComponent, children:[
        {path:'', component:ResetPasswordComponent},
        {path:'mail-send', component:RecoverMailSendComponent},
    ]},
    { path: '**', component: NotFoundComponent }
];