import { HttpClient, HttpHeaders, HttpParams, HttpParamsOptions } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { ApiHttpResponse, Country } from '@kinomap-dev/ng-ui-lib';
import { map, Observable } from 'rxjs';
import { environment } from '../../environments/environment';
import { ActivityDurationPreference } from '../pages/favorite-activity/favorite-activity.component';
import { SignUpResponse } from '../models/sign-up-response.model';

@Injectable({
  providedIn: 'root',
})
export class ApiService {

  constructor(private readonly http: HttpClient) { }

  getCountries(): Observable<Country[]> {
    return this.http.get<ApiHttpResponse<Country[]>>(`${environment.urls.api_url}countries/list?appToken=${environment.websiteAppToken}`).pipe(
      map((response: ApiHttpResponse<Country[]>) => {
        return response.data;
      })
    )
  };

  signUp(
    name: string,
    mail: string,
    countryId: number,
    md5Password: string,
    lang: string // fr-FR
  ): Observable<SignUpResponse> {
    const url: string = `${environment.urls.api_url}auth/signUp?appToken=${environment.websiteAppToken}`
    return this.http.post<ApiHttpResponse<SignUpResponse>>(url, {
      email: mail,
      md5Pwd: md5Password,
      userName: name,
      countryId: countryId,
      lang: lang
    }).pipe(
      map((response) => {
        return response.data;
      })
    )
  };

  /**
   * Met à jour le profil de l'utilisateur
   * @param environments 
   * @param sessionPerWeek 
   * @param averageSessionDuration 
   * @param gender 
   * @param fullname 
   * @param height 
   * @param weight 
   * @param birthdate 
   * @returns 
   */
  updateProfile(
    environments: string[],
    sessionPerWeek: number | null,
    averageSessionDuration: ActivityDurationPreference | null,
    gender: 'M' | 'F' | null,
    fullname: string | null,
    height: number | null,
    weight: number | null,
    birthdate: string | null,
  ): Observable<any> {
    const url: string = `${environment.urls.api_url}me/account/profile?appToken=${environment.websiteAppToken}`;
    let params: HttpParams = new HttpParams();
    if (gender) params.append('gender', gender);
    if (fullname) params.append('fullname', fullname);
    if (birthdate) params.append('birthdate', birthdate);
    if (sessionPerWeek) params.append('sessionPerWeek', sessionPerWeek);
    if (averageSessionDuration) params.append('averageSessionDuration', averageSessionDuration);
    if (height) params.append('height', height);
    if (weight) params.append('weight', weight);
    environments.forEach((landscape: string) => {
      params.append('environments[]', landscape)
    })

    return this.http.post(url, params)
  };

  /**
   * Envoie un mail de récupération de mot de passe
   * @param email 
   * @returns 
   */
  recover(email: string): Observable<{ data: { message: string } }> {
    const url: string = `${environment.urls.api_url}users/recover?appToken=${environment.websiteAppToken}`;
    return this.http.post<{ data: { message: string } }>(url, {
      login: email
    })
  }
}
