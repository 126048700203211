import { Component, OnDestroy, OnInit } from '@angular/core'
import { AsyncPipe, NgClass, UpperCasePipe } from '@angular/common'
import { OauthService } from '../../Services/oauth.service'
import { BehaviorSubject, Subscription } from 'rxjs'
import { ClientInformations } from '../../Models/clientInformation.interface'
import { TokenAccess } from '../../Models/tokenAccess.interface'
import { codeResponse } from '../../Models/codeResponse.interface'
import { errorTypes } from '../../Models/errorTypes.interface'
import { environment } from '../../../environments/environment'
import { Router } from '@angular/router'
import { TranslateModule, TranslateService } from '@ngx-translate/core'
import { LoaderComponent } from '../../components/loader/loader.component'
import { BreakpointObserver, Breakpoints } from '@angular/cdk/layout'
import { Client } from '../../Models/client.interface'

@Component({
  selector: 'app-authorize',
  standalone: true,
  imports: [
    TranslateModule,
    UpperCasePipe,
    AsyncPipe,
    NgClass,
    LoaderComponent,
  ],
  templateUrl: './authorize.component.html',
  styleUrl: './authorize.component.scss',
})
export class AuthorizeComponent implements OnInit, OnDestroy {
  xSmallScreen: boolean = false

  clientInformations: ClientInformations | null = null
  tokenAccess: TokenAccess | null = null
  subscriptions: Subscription[] = []
  loading$: BehaviorSubject<boolean> = new BehaviorSubject<boolean>(false)

  client: Client | undefined
  titleTranslation: string = ''
  requestTranslation: string = ''
  termsTranslation: string = ''
  termsLinkTranslation: string = ''

  constructor(
    private oauthService: OauthService,
    private observer: BreakpointObserver,
    private router: Router,
    private translate: TranslateService,
  ) { }

  navtoLogin() {
    window.location.href = this.clientInformations!.redirect_uri
  }

  allowAuthorize() {
    this.loading$.next(true)

    //? Si c'est un callback de TECHNOGYM (mobile)
    if (this.clientInformations !== null && this.clientInformations?.client_id === '3' && this.clientInformations.redirect_uri !== undefined) {
      setTimeout(() => {
        window.location.href = this.clientInformations!.redirect_uri;
        this.loading$.next(false)
      })

    } else { //? Si ce n'est PAS un callback de TECHNOGYM !
      this.tokenAccess = this.oauthService.get_storage_token()
      if (this.clientInformations !== null && this.tokenAccess !== null) {
        this.subscriptions = [
          ...this.subscriptions,
          this.oauthService
            .get_oauth_code(this.clientInformations, this.tokenAccess)
            .subscribe((response: codeResponse) => {
              if (this.clientInformations) {
                if (!response.code) {
                  this.oauthService.redirect_error(
                    this.clientInformations.redirect_uri,
                    errorTypes.ARGS_INVALID,
                  )
                  this.router.navigate(['/not-found'])
                }
                this.oauthService.redirect(
                  this.clientInformations.redirect_uri,
                  response.code,
                )
              }
              this.loading$.next(false)
            }),
        ]
      }
    }
  }

  ngOnInit(): void {
    this.subscriptions = [
      ...this.subscriptions,
      this.observer
        .observe(Breakpoints.XSmall)
        .subscribe((res) => (this.xSmallScreen = res.matches)),
    ]

    this.clientInformations = this.oauthService.clientInformations

    if (this.clientInformations && this.clientInformations.client_id) {
      const clientsList: Client[] = environment.clientsList;
      this.client = clientsList.find(client => client.client_id === this.clientInformations?.client_id)

      if (!this.client) {
        console.error(`❌ client_id : ${this.clientInformations.client_id} is not a kinomap client_id.`)
        this.router.navigate(['/not-found'])
      }

      //? initialize tranlsations string.
      this.titleTranslation = this.translate
        .instant('oauth.authorize.title')
        .replace(
          '${clientName}',
          `<span class="strong">${this.client!.client_name}</span>`,
        )
        .replace(
          '${KinomapStongClass}',
          '<span class="strong">kinomap</span>',
        )
      this.requestTranslation = this.translate
        .instant('oauth.authorize.request')
        .replace(
          '${clientName}',
          `<span class="strong">${this.client!.client_name}</span>`,
        )
      this.termsLinkTranslation = `<a href="https://www.kinomap.com/v2/terms" target="_blank">${this.translate.instant(
        'oauth.authorize.termsLink',
      )}</a>`
      this.termsTranslation = this.translate
        .instant('oauth.authorize.terms')
        .replace('${kinTermsOfServiceLink}', this.termsLinkTranslation)
    } else {
      console.error(`Client not found.`)
      this.router.navigate(['/not-found'])
    }
  }

  ngOnDestroy(): void {
    this.subscriptions.forEach((sub) => sub.unsubscribe())
  }
}
