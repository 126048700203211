import { Component } from '@angular/core';
import { jwtDecode, JwtPayload } from "jwt-decode";
import { Subscription } from 'rxjs';
import { UserService } from '../../services/user.service';
import { Author, CircleUserComponent, User } from '@kinomap-dev/ng-ui-lib';

@Component({
  selector: 'app-change-account',
  standalone: true,
  imports: [CircleUserComponent],
  templateUrl: './change-account.component.html',
  styleUrl: './change-account.component.scss'
})
export class ChangeAccountComponent {
  token: string | null = localStorage.getItem('token');
  userSubcription: Subscription | undefined;
  client:User | undefined;
  circleUserData: Author | null = null;

  constructor(private userService: UserService) { }

  ngOnInit(): void {
    if (this.token !== null) {
      const decodeToken: JwtPayload = jwtDecode(this.token);
      if (decodeToken.sub) {
        this.userSubcription = this.userService.getUser(decodeToken.sub).subscribe({
          next:(user:User) => {
            this.client = user;
            this.circleUserData = {
              avatar_url: user.avatar_url,
              country: undefined,
              username: user.username,
              id: user.id,
            }
          },
          error:(error:Error) => {
            console.error(error)
          },
        })
      }
    }
  }


  ngOnDestroy(): void {

  }
}
