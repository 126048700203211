<div class="onboarding-content">

    <div class="steper">
        @for(step of childrens;track $index){
        <div class="step" [ngClass]="{'active' : currentPath === step}"></div>
        }
    </div>

    <div class="content-router">
        <router-outlet></router-outlet>
    </div>

</div>