<div id="login" [ngClass]="{ xSmallScreen: xSmallScreen, largeScreen: largeScreen }">
  <form [formGroup]="loginForm" (ngSubmit)="login()" class="login-form">

    <!--* MAIL -->
    @let usernameError = loginForm.controls['username'].invalid && loginForm.controls['username'].touched;
    <div class="username icon-input" (focus)="usernameFocused = true" (blur)="usernameFocused = false"
      [ngClass]="{'input-error': usernameError, touched: usernameFocused || loginForm.controls['username'].touched}">
      <div class="input-header">
        <label for="username">{{ 'oauth.login.placeholders.username_mail' | translate }}</label>
      </div>
      <kin-icon name='mail' [color]="black100"></kin-icon>

      <input #passwordInput (focus)="usernameFocused = true" (blur)="usernameFocused = false" type="username"
        id="username" formControlName="username">
      <p class="form-error">
        @if (usernameError && loginForm.controls['username'].errors &&
        loginForm.controls['username'].errors['required']) {
        {{ 'sign_up.input.name.mail.error.required' | translate }}
        }
        @if(username?.errors?.['minLength']) {
        {{ 'oauth.login.errors.mail_too_short' | translate }}
        }
      </p>
    </div>

    <!-- * PASSWORD -->
    @let passwordError = loginForm.controls['password'].invalid && loginForm.controls['password'].touched;
    <div class="password icon-input" (focus)="isPasswordFocused = true" (blur)="isPasswordFocused = false"
      [ngClass]="{'input-error': passwordError, touched: isPasswordFocused || loginForm.controls['password'].touched}">
      <div class="input-header">
        <label for="password" translate>sign_up.input.password.label</label>
      </div>
      <kin-icon [name]="loginForm.controls['password'].valid ? 'lock_open' : 'lock'" [color]="black100"></kin-icon>
      <input #confirmPasswordInput (focus)="isPasswordFocused = true" (blur)="isPasswordFocused = false"
        [type]="passwordVisible ? 'text' : 'password'" id="password" formControlName="password">
      <kin-icon (iconClick)="passwordVisible = !passwordVisible" [name]="passwordVisible ?  'eye_on' : 'eye_off'"
        [color]="black200"></kin-icon>
      <p class="form-error">
        @if (passwordError && loginForm.controls['password'].errors?.['required']) {
        {{ 'sign_up.input.password.error.required' | translate }}
        }
        @if (passwordError && loginForm.controls['password'].errors?.['minlength']) {
        @let minLengthRequied = loginForm.controls['password'].errors?.['minlength']?.requiredLength;
        {{('sign_up.input.password.error.minlength' | translate).replace('{minlength}', minLengthRequied)}}
        }
        @if (passwordError && loginForm.controls['password'].errors?.['maxlength']) {
        @let maxLengthRequied = loginForm.controls['password'].errors?.['maxlength']?.requiredLength;
        {{('sign_up.input.password.error.maxlength' | translate).replace('{maxlength}', maxLengthRequied)}}
        }
      </p>
    </div>

    <button [disabled]="!loginForm.valid" class="btn-primary" type="submit">
      {{ (loading$ | async) ? '' :('oauth.commun.log_in' | translate | uppercase) }}
      <app-loader [loading]="(loading$ | async)"></app-loader>
    </button>

    <div class="forgot_password" [routerLink]="'/recover'">{{'oauth.forgot_password' | translate }}</div>

    <p class="connect_with">{{'oauth.connect_with' | translate }}</p>

    <div id="sso-container">

      <div id="sso-wrapper">
        @for (ssoItem of ssoItems; track ssoItem){
        <div class="sso-item" [ngStyle]="{ background: ssoItem.color }">
          <img [src]="ssoItem.logo_url" [alt]="ssoItem.key + ' logo.'" (click)="ssoLogin(ssoItem.key)" />
        </div>
        }
      </div>
    </div>
  </form>

  <footer>
    <p>{{ 'oauth.login.dont_have_an_account' | translate }}</p>
    <a [routerLink]="['sign-up']">{{ 'oauth.sign_up' | translate }}</a>
  </footer>
</div>